import { CButton, CInput, CInputGroup } from "@coreui/react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
// import moment from "moment"

export const getRandomAlphaNumericString=(length)=>{
    let result = "";
    for (let i = 0; i < length; i++) {
      result += getRandomAlphaNumeric();
    }
    return result;
}
function getRandomAlphaNumeric() {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const randomIndex = Math.floor(Math.random() * characters.length);
  return characters[randomIndex];
}

  export const renamingKeys = (obj, keyNamingConvention) => {
    const renamedData = obj.map((item) => {
      const newItem = {};
      for (const oldKey in item) {
        if (oldKey in keyNamingConvention) {
          newItem[keyNamingConvention[oldKey]] = item[oldKey];
        } else {
          newItem[_.startCase(oldKey)] = item[oldKey];
        }
      }
      return newItem;
    });
    return renamedData;
  };

  export const generateUrl = (baseURL, pageSizeApi, page, expand, filterString, urlEndPoint, additionalOdataParamsForAPI, isPageSize, isUrlDoubleEncode) => {
    const params = new URLSearchParams();
   
    params.append('$count', 'true');
   
    if (pageSizeApi) {
      params.append(isPageSize ? 'pagesize' : '$top', pageSizeApi);
      params.append('$skip', ((page - 1) * pageSizeApi).toString());
    }
   
    if (expand) {
      params.append('$expand', expand);
    }
   
    if (filterString) {
      const encodedFilter = encodeODataFilter(filterString);
      params.append('$filter', isUrlDoubleEncode ? encodedFilter : filterString);
    }
   
    if (additionalOdataParamsForAPI) {
      const additionalParams = new URLSearchParams(additionalOdataParamsForAPI);
      additionalParams.forEach((value, key) => {
        params.append(key, value);
      });
    }
   
    return `${baseURL}${urlEndPoint}?${params.toString()}`;
}

function encodeODataFilter(filter) {
    // Split the filter string into parts (operators and values)
    const parts = filter.split(/(\s+)/);
    
    return parts.map(part => {
        // Don't encode OData operators and parentheses
        if (/^(eq|ne|gt|ge|lt|le|and|or|not|contains|startswith|endswith|\(|\))$/i.test(part)) {
            return part;
        }
        // Encode values, preserving single quotes for strings
        if (part.startsWith("'") && part.endsWith("'")) {
            return "'" + encodeURIComponent(part.slice(1, -1)) + "'";
        }
        // Encode other parts
        return encodeURIComponent(part);
    }).join('');
}
export const gridLayoutLoad = (activeTab, gridRef, gridStateRef) => {
  try {
    const viewDetails = activeTab?.viewDetails ? JSON.parse(activeTab?.viewDetails) : "";
    if (!gridRef.current) {
      return;
    }

    // Ensure gridStateRef.current is defined before calling applyStateFromString
    if (gridStateRef?.current && typeof gridStateRef.current.applyStateFromString === 'function') {
      gridStateRef.current.applyStateFromString(activeTab?.viewDetails, ['sorting', 'filtering', 'groupBy']);
    } else {
      console.warn('gridStateRef.current or applyStateFromString is not available');
    }

    const applyColumnOperations = () => {
      let hiddenCount = 0
      viewDetails.columns.forEach((columnState, index) => {
        // const actualColumn = gridRef.current.getColumnByVisibleIndex(index)
        const targetColumn = gridRef.current.getColumnByName(columnState.field);
        // Ensure targetColumn exists before accessing its properties or methods
        if (targetColumn) {
          // if (actualColumn?.field !== targetColumn.field) {
            if (!columnState?.hidden) {
              targetColumn.move(index - hiddenCount);
            } else {
              hiddenCount++;
            }
          // }
          targetColumn.hidden = columnState?.hidden === null ? true : columnState?.hidden;
          targetColumn.pinned = columnState?.pinned === null ? false : columnState?.pinned;
          targetColumn.width = columnState?.width === null ? '150px' : columnState?.width;
        }
      });
    };

    // Run the column operations twice
    applyColumnOperations();
    setTimeout(() => {
      applyColumnOperations();
    }, 0);
  
    } catch (error) {
    console.error('Error in gridLayoutLoad:', error);
    // You can add additional error handling logic here if needed
  }
}

  // function parseFlexibleDate(dateString) {
  //   let parsedDate = moment(dateString, moment.ISO_8601, true);
    
  //   if (!parsedDate.isValid()) {
  //     // If ISO 8601 parsing fails, try parsing with no specific format
  //     parsedDate = moment(dateString);
  //   }
  
  //   if (parsedDate.isValid()) {
  //     return parsedDate.toDate(); // Convert to JavaScript Date object
  //   }
  
  //   return null; // Return null if parsing fails
  // }
    
/**
 * Flattens an array of objects and applies modifications to the grid data.
 *
 * @param {Array} data - The array of objects received from the API call per page or total data.
 * @param {Object} columnHeaders - An object containing the mapping of original column names to new column names.
 * @param {Function} modifyColumnData - A function to modify column data, such as converting date format strings to JS date objects or adding custom key-value pairs.
 * @param {Array} columnsToModify - An array of column names indicating which data to modify in the API object.
 * @param {Array} nestedArrayToModify - An array of nested array key names to modify that data.
 * @param {number} batchSize - creating plain objects for batch wise default batch is 1000
 * @returns {Array} - A single array of objects with the required modifications to the grid data.
 */
export const flattenArrayOfObjects = (data, columnHeaders = {}, modifyColumnData = null, columnsToModify = [], nestedArrayToModify = [], batchSize = 1000) => {
  const flattenedData = [];

  function flattenObject(obj, prefix = '') {
    let result = [{}];

    Object.entries(obj).forEach(([key, value]) => {
      const newKey = prefix ? `${prefix}__${key}` : key;

      if (Array.isArray(value) && !_.some(nestedArrayToModify, column => _.includes(key, column))) {
        if (value.length > 0 && typeof value[0] === 'object') {
          // Handle array of objects
          const flattenedArrayObjects = value.flatMap(item => flattenObject(item, newKey));
          result = result.flatMap(existingObj => 
            flattenedArrayObjects.map(newObj => ({...existingObj, ...newObj}))
          );
        } else {
          // For simple arrays, we'll stringify the value
          result.forEach(item => item[newKey] = JSON.stringify(value));
        }
      } else if (_.isPlainObject(value) && !_.some(nestedArrayToModify, column => _.includes(key, column))) {
        // Handle nested objects
        const nested = flattenObject(value, newKey);
        result = result.flatMap(existingObj => 
          nested.map(newObj => ({...existingObj, ...newObj}))
        );
      } else {
        // if (typeof value === 'string' && isDateTimeString(value)) {
        //   const shouldModify = !columnsToModify.some(column => newKey.includes(column));
        //   if (shouldModify) {
        //     value = parseFlexibleDate(value);
        //   }
        // }
        result.forEach(item => {
          if (modifyColumnData && _.some(columnsToModify, column => _.includes(newKey, column))) {
            if (newKey.includes('__')) {
              const parts = newKey.split("__");
              item = modifyColumnData(newKey, parts[parts.length - 1], value, item);
            } else {
              item = modifyColumnData(newKey, key, value, item);
            }
          } else {
            if (columnHeaders.hasOwnProperty(newKey)) {
              item[columnHeaders[newKey]] = value;
            } else {
              if (newKey.includes('__')) {
                const parts = newKey.split("__");
                item[_.startCase(parts[parts.length - 1])] = value;
              } else {
                item[_.startCase(newKey)] = value;
              }
            }
          }
        });
      }
    });

    return result;
  }

  // const isDateTimeString = (str) => {
  //   if (typeof str !== 'string') return false;
  //   const formats = [
  //     // ISO 8601 formats
  //     'YYYY-MM-DDTHH:mm:ss.SSSZ',
  //     'YYYY-MM-DDTHH:mm:ssZ',
  //     'YYYY-MM-DDTHH:mm:ss',
      
  //     // Common datetime formats
  //     'YYYY-MM-DD HH:mm:ss',
  //     'MM/DD/YYYY HH:mm:ss',
  //     'DD/MM/YYYY HH:mm:ss',
  //     'YYYY/MM/DD HH:mm:ss',
      
  //     // Formats with month names
  //     'MMMM D, YYYY HH:mm:ss',
  //     'D MMMM YYYY HH:mm:ss',
      
  //     // Formats with abbreviated month names
  //     'MMM D, YYYY HH:mm:ss',
  //     'D MMM YYYY HH:mm:ss',
      
  //     // Formats with day of week
  //     'dddd, MMMM D, YYYY HH:mm:ss',
      
  //     // 12-hour time formats
  //     'YYYY-MM-DD hh:mm:ss A',
  //     'MM/DD/YYYY hh:mm:ss A',
  //     'DD/MM/YYYY hh:mm:ss A',
  //     'YYYY/MM/DD hh:mm:ss A',
  //     'MMMM D, YYYY hh:mm:ss A',
  //     'D MMMM YYYY hh:mm:ss A',
  //     'MMM D, YYYY hh:mm:ss A',
  //     'D MMM YYYY hh:mm:ss A',
  //     'dddd, MMMM D, YYYY hh:mm:ss A'
  //   ];
    
  //   const m = moment(str, formats, true);
  //   return m.isValid();
  // };

  // Handle both array of objects and single object
  const processData = (dataToProcess) => {
    if (Array.isArray(dataToProcess)) {
      dataToProcess.forEach(obj => {
        flattenedData.push(...flattenObject(obj));
      });
    } else if (_.isPlainObject(dataToProcess)) {
      flattenedData.push(...flattenObject(dataToProcess));
    }
  };

  // Process data in batches to handle large datasets
  if (Array.isArray(data) && data.length > batchSize) {
    for (let i = 0; i < data.length; i += batchSize) {
      const batch = data.slice(i, i + batchSize);
        processData(batch);
      // Here you would typically yield control back to the UI thread
      // In a browser environment, you might use setTimeout
      // In a Node.js environment, you might use setImmediate or process.nextTick
    }
  } else {
    processData(data);
  }

  return flattenedData;
};



export function SearchInput(
  search,
  setSearch,
  setIsXIconVisible,
  searchData,
  isXIconVisible,
  onClickXIcon,
  searchPlaceHolder,
  searchInputDisable=false
) {
  return (
    <CInputGroup className="mb-3 ml-1">
      <CInput 
        type="text"
        name="Search"
        value={search}
        autoComplete="off"
        placeholder={searchPlaceHolder}
        className={`${isXIconVisible ? "padding-right-25" : ""}`}
        disabled={searchInputDisable}
        onChange={(e) => {
          const inputValue = e?.target?.value;
          if (inputValue === " ") {
            return;
          }
          setSearch(inputValue);
          if (inputValue) {
            setIsXIconVisible && setIsXIconVisible(true);
          } else {
            onClickXIcon();
          }

        }}
        onKeyDown={(e) => {
          if ((e?.key === "Enter" || e?.keyCode === 13 || e?.which === 13) && search) {
            e?.preventDefault(); // Prevent default form submission
            e?.target?.blur(); // Remove focus
            searchData();
          }
        }}
      />
      <CButton
        className={`btn-primary rounded-right Search-input-radius`}
        onClick={() => {
          document.activeElement.blur(); // Remove focus from any active element
          searchData();
        }}
        disabled={search === ""}
      >
        <FontAwesomeIcon className="" icon={faSearch} />
      </CButton>
      {isXIconVisible ? (
        <i
          className="fa fa-times iconstyle text-danger"
          onClick={onClickXIcon}
        ></i>
      ) : (
        ""
      )}
    </CInputGroup>
  );
}